<template>
    <zw-sidebar @shown="shown" :title="$t('article.title.change_attribute')">
        <div class="px-3 py-2">
            <validation-observer tag="div" ref="observer">
                <b-overlay :show="loading" no-wrap></b-overlay>
                <div v-if="!loading">
                    <b-row>
                        <b-col cols="12">
                            <b-alert variant="info" show v-if="form.source == 'selected'">
                                <font-awesome-icon icon="info-circle" class="mr-2"></font-awesome-icon>
                                {{ $t('article.text.selected_count', {'count': payload.selectedData.ids.length}) }}
                            </b-alert>
                            <b-alert variant="info" show v-else>
                                <font-awesome-icon icon="info-circle" class="mr-2"></font-awesome-icon>
                                {{ $t('export.source.' + form.source) }}
                            </b-alert>
                        </b-col>
                    </b-row>

                    <template v-if="form.type == 'article_attributes' || form.type == 'article_properties'">
                        <b-row v-for="(attribute, index) in getAttributesFiltered()" :key="index">
                            <b-col cols="6">
                                {{ attribute.label }}
                            </b-col>
                            <b-col cols="6">
                                <multiselect v-model="form.new_values[attribute.id]"
                                             :name="'new_values['+attribute.id+']'"
                                             :searchable="true"
                                             :multiple="form.type == 'article_properties'"
                                             :options="getAttributeOptions(attribute.children)"
                                             select-label=""
                                ></multiselect>
                            </b-col>
                        </b-row>
                    </template>
                    <template v-else>
                        <b-row>
                            <b-col cols="6">
                                <zw-select-group v-model="form.attribute"
                                                 :options="filteredAttributesNames | optionsVV"
                                                 name="attribute"
                                                 :label-prefix="labelPrefix"
                                                 validate="required"
                                                 @change="(val)=>{changeAttribute(val)}"
                                ></zw-select-group>
                            </b-col>

                            <b-col cols="6">
                                <component
                                    v-if="Object.keys(fields).includes(form.type) && Object.keys(fields[form.type]).includes(form.attribute)"
                                    v-bind:is="fields[form.type][form.attribute]"
                                    v-model="form.new_value"
                                    :label-prefix="labelPrefix"
                                    :old-value="form.old_value"
                                    :ids="form.ids"
                                    :mass-methods="form.mass_methods"
                                    @input-old="oldValueChanged"
                                    @input-mass-method="massMethodChanged"
                                ></component>
                                <template v-else>
                                    <template v-if="attributeType == 'select'">
                                        <zw-select-group v-model="form.new_value"
                                                         :options="attributeOptions"
                                                         name="new_value"
                                                         :label-prefix="labelPrefix"
                                        ></zw-select-group>
                                    </template>
                                    <template v-else>
                                        <zw-input-group v-model="form.new_value"
                                                        name="new_value"
                                                        :label-prefix="labelPrefix"
                                        ></zw-input-group>
                                    </template>
                                </template>
                            </b-col>
                        </b-row>
                    </template>

                    <b-row>
                        <b-col sm="12" class="text-xs-center">
                            <b-button block @click="onSubmit()" variant="primary">
                                {{ $t('common.button.save') }}
                            </b-button>
                        </b-col>
                    </b-row>
                </div>
            </validation-observer>
        </div>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import ZwMedia from "../../components/ZwMedia";

export default {
    mixins: [commonSave],
    components: {ZwMedia},
    data() {
        return {
            loading: true,
            payload: {},
            callback: null,
            filteredAttributesNames: [],
            attributeType: 'select',
            attributeOptions: {},
            defaultForm: {
                regenerate_name: false,
                mass_methods: 'add',
                new_value: '',
                new_values: [],
                attribute: null,
                source: 'selected',
                ids: [],
                id: 1, // for update message
            },
            type: '',
            form: {},
            labelPrefix: 'article.label.change_attribute.',
            fields: {
                'offering': {
                    'shipping': () => import('./offering_attributes/shipping'),
                    'status': () => import('./offering_attributes/status'),
                    'payment_method': () => import('./offering_attributes/payment_method'),
                    'payment_status': () => import('./offering_attributes/payment_status'),
                    'invoice_status': () => import('./offering_attributes/invoice_status'),
                    'fulfilment_status': () => import('./offering_attributes/fulfilment_status'),
                    'shipping_status': () => import('./offering_attributes/shipping_status'),
                },
                'article': {
                    'vat': () => import('./article_attributes/vat'),
                    'type': () => import('./article_attributes/type'),
                    'base_image': () => import('./article_attributes/base_image'),
                    'category_ids': () => import('./article_attributes/category_ids'),
                    'bar_code': () => import('./article_attributes/bar_code'),
                    'name': () => import('./article_attributes/name'),
                },
                'customer': {
                    'price_list_id': () => import('./customer_attributes/price_list_id'),
                    'status': () => import('./customer_attributes/status'),
                    'customerType': () => import('./customer_attributes/customerType'),
                    'customerGroups': () => import('./customer_attributes/customerGroups'),
                    'customerTags': () => import('./customer_attributes/customerTags.vue'),
                    'customerForm': () => import('./customer_attributes/customerForm'),
                    'country': () => import('./customer_attributes/country'),
                    'active': () => import('./customer_attributes/active'),
                },
            }
        }
    },
    methods: {
        ...mapGetters('MassUpdate', ['getAttributesFiltered']),
        ...mapGetters('Export', ['getSources']),
        shown() {
            const attributesFiltered = this.$store.dispatch('MassUpdate/fetchAttributesFiltered', {
                attribute_type: this.payload.type,
                ...this.payload.selectedData
            })

            const exportOptions = this.$store.dispatch('Export/fetchOptions')

            Promise.all([attributesFiltered, exportOptions])
                .then(() => {
                    this.form = JSON.parse(JSON.stringify(this.defaultForm))
                    this.form.ids = this.payload.selectedData.ids
                    this.form.type = this.payload.type
                    this.form.source = this.payload.selectedData.selectedSource

                    for (const [key, value] of Object.entries(this.getAttributesFiltered())) {
                        if (typeof value == 'object') {
                            this.filteredAttributesNames.push(value.name)
                        } else {
                            this.filteredAttributesNames.push(value)
                        }
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },
        changeAttribute(attribute) {
            this.attributeType = 'text'
            this.attributeOptions = {}
            for (const [key, value] of Object.entries(this.getAttributesFiltered())) {
                if (typeof value == 'object') {
                    if (value.name == attribute) {
                        this.attributeType = value.type
                        this.attributeOptions = value.options
                    }
                }
            }
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true
                    if (this.form.new_values.length) {
                        this.form.new_value = this.form.new_values
                        this.form.attribute = this.form.type
                    }

                    let sendData = {
                        form: this.form,
                        ...this.payload.selectedData
                    }

                    this.$store.dispatch('MassUpdate/updateAttributes', sendData)
                        .then((response) => {
                            this.commonAfterSave(response)
                        })
                        .catch(errors => {
                            this.$refs['observer'].setErrors(errors)
                            this.showValidationError()
                        })
                        .finally(() => {
                            this.loading = false
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
        getAttributeOptions(data) {
            let options = []
            data.forEach((val, index) => {
                options.push(val[0])
            })
            return options
        },
        oldValueChanged(val) {
            this.$set(this.form, 'old_value', val)
        },
        massMethodChanged(val) {
            this.$set(this.form, 'mass_methods', val)
        }
    },
}
</script>